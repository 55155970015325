exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-approach-js": () => import("./../../../src/pages/approach.js" /* webpackChunkName: "component---src-pages-approach-js" */),
  "component---src-pages-healthcenter-map-js": () => import("./../../../src/pages/healthcenter-map.js" /* webpackChunkName: "component---src-pages-healthcenter-map-js" */),
  "component---src-pages-impact-js": () => import("./../../../src/pages/impact.js" /* webpackChunkName: "component---src-pages-impact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-learnings-js": () => import("./../../../src/pages/learnings.js" /* webpackChunkName: "component---src-pages-learnings-js" */),
  "component---src-pages-opportunity-js": () => import("./../../../src/pages/opportunity.js" /* webpackChunkName: "component---src-pages-opportunity-js" */),
  "component---src-pages-our-team-js": () => import("./../../../src/pages/our-team.js" /* webpackChunkName: "component---src-pages-our-team-js" */),
  "component---src-pages-partnerships-js": () => import("./../../../src/pages/partnerships.js" /* webpackChunkName: "component---src-pages-partnerships-js" */),
  "component---src-pages-sso-map-js": () => import("./../../../src/pages/sso-map.js" /* webpackChunkName: "component---src-pages-sso-map-js" */)
}

